import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import deJSON from './locale/de.json';
import enJSON from './locale/en.json';
import frJSON from './locale/fr.json';

i18n.use(initReactI18next).init({
    resources: {
        de: { translation: deJSON },
        en: { translation: enJSON },
        fr: { translation: frJSON }
    },
    lng: 'de', // Sets the initial language of the App
    interpolation: {
        escapeValue: false // react already safes from xss
    }
});

export default i18n;