import React, { useContext } from 'react';
import WeekNavigation from "./WeekNavigation";
import { Link } from 'react-router-dom';

import { Context } from '../context';
import {useTranslation} from "react-i18next";

const Mike5 = () => {
    const { workouts, releaseWakeLock, activeMike5Workout } = useContext(Context);
    const { t} = useTranslation();

    releaseWakeLock();

    return (
        <div className="page page--dashboard">
            <WeekNavigation type='calendar' />
            <div className='workout-list'>
                <div className='workout-list__wrapper'>
                    <h2 className='workout-list__title'>{t('mike5Today')}</h2>
                    <div className='workout-list__teaser-wrapper'>
                        <div className='workout-list__teaser' style={{backgroundImage: `url(${activeMike5Workout?.appImage?.['big5'].url || ''})`}}>
                            <Link to="/workout/mike5/big5" className='workout-list__workout'>
                                BIG 5
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='workout-list__wrapper'>
                    <div className='workout-list__teaser-wrapper'>
                        <div className='workout-list__teaser' style={{backgroundImage: `url(${activeMike5Workout?.appImage?.['assistance'].url || ''})`}}>
                            <Link to="/workout/mike5/assistance" className='workout-list__workout'>
                                Assistance
                            </Link>
                        </div>
                        <div className='workout-list__teaser' style={{backgroundImage: `url(${activeMike5Workout?.appImage?.['core'].url || ''})`}}>
                            <Link to="/workout/mike5/core" className='workout-list__workout'>
                                Core
                            </Link>
                        </div>
                        {Object.values(workouts).filter(workout => workout && workout.workoutType !== 'mike5' && workout.title === 'BIG5 WarmUp').map(workout => (
                            <div className='workout-list__teaser' style={{backgroundImage: `url(${workout?.appImage || ''})`}}>
                                <Link to={`/workout/${workout?.slug || ''}`} className='workout-list__workout'>
                                    {workout?.appTitle || workout?.title || ''}
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='workout-list__wrapper'>
                    <h2 className='workout-list__title'>{t('newTrainee')}</h2>
                    <div className='workout-list__teaser-wrapper'>
                        {Object.values(workouts).filter(workout => workout && workout.workoutType !== 'mike5' && (workout.title === 'Einsteiger Workout' || workout.title === 'Beginner workout' || workout.title === 'Entraînement pour débutants')).map(workout => (
                            <div className='workout-list__teaser' style={{backgroundImage: `url(${workout?.appImage || ''})`}}>
                                <Link to={`/workout/${workout?.slug || ''}`} className='workout-list__workout'>
                                    {workout?.appTitle || workout?.title || ''}
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mike5;